import React, { ReactElement } from 'react'
import { Layout, SEO } from 'app/components'
import { Donate } from 'app/components/donate'

const DonatePage = (): ReactElement => (
  <Layout headerColors={[null, null, null, null, null]}>
    <SEO title='Поддержать Крепость' />
    <Donate />
  </Layout>
)

export default DonatePage
